<template>
  
  <div class="md-layout md-alignment-center-center" id="pdfArea">
      <div class="header-dashboard" id="headerBox">
        <div class="pdfDownLoadBtn" id="pdfDownLoadBtn" @click="()=>exportPDF()">PDF 다운로드</div>
  
        <div class="datePickerBox">
          <Datepicker
              v-model="selectedDate"
              :disabled-end-date="disabledEndDate"
              range
              lang="en"
              :date-format=dataForm
              position="right"
              />
            <button class="dashboard-btn" @click="()=>inquiryData()">조회</button>
        </div>
      </div>

      <div class="total-visits-block">
        <div>총 방문자 수</div>
        <div style="font-size: 25px; margin-top: 0.5rem;">{{ totalVisits }}</div>
      </div>
      <!-- <div class="wrapper-total-visits"> -->
        <div class="total-visits">
          <!-- <div class="total-visits-header">방문자 활동 통계</div> -->
          <GoogleChart
            :type="visitChartType"
            :data="chartData"
            :options="chartOptions"
          />
        </div>
      <!-- </div> -->

    <div class="country-visits">
      <div class="country-visits-line">
        <div class="countryTitle">국가 & 언어별 방문자 통계</div>
        <GoogleChart 
          :type="'GeoChart'"
          :data="countryData"
          :options="countryDataOptions"
          :settings="{packages: ['geochart']}" 
        />
      </div>
  
      <div class="country-visits-bar">
        <GoogleChart 
          :type="'ColumnChart'"
          :data="countryBarData"
          :options="countryBarOptions"
        />
      </div>
    </div>

    <div class="country-visits">

      <div class="country-visits-line">
        <!-- <div class="total-visits-header">제품 클릭 횟수 상위 10개 막대 차트</div> -->
        <!-- <chart-card 
          :chart-data="productData"
          :chart-options="productOptions"
          chart-type="Bar"
          chart-inside-header
          background-color="#2ea178"
        /> -->
        <GoogleChart 
          :type="'ColumnChart'"
          :data="productData"
          :options="productOptions"
        />
      </div>
  
      <div class="country-visits-bar">
        <md-card style="margin: 0px;">
          <div class="productTitle">제품 클릭 횟수 상위 10개 막대 차트</div>
          <md-card-content>
            <md-table v-if="productData.length > 0" >
              <md-table-row slot="md-table-row"  v-for="(value, index) in productData" :key="index">
                <md-table-cell v-if="index == 0">No.</md-table-cell>
                <md-table-cell v-if="index != 0">{{ index }}</md-table-cell>
                <md-table-cell >{{ value[0] }}</md-table-cell>
                <md-table-cell >{{ value[1] }}</md-table-cell>
              </md-table-row>
            </md-table>

          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { mapActions } from 'vuex';
import api from "@/commons/apiUrl";
import GoogleChart from "./Components/GoogleChart.vue";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

export default {
  name : 'Dashboard',
  components: {
    GoogleChart
  },
  data() {
    return {
      visitChartType : 'LineChart',
      chartData : [
        ['Days', '방문자수'],
      ],
      chartOptions : {
        title: "방문자 활동 통계",
        width: '100vw',
        height: 600,
        series: {
          0: { color: "#2ea178" },
        },
        legend: { position: 'top' }
      },
      code : null,
      totalVisits : 0,
      countryData: [
        ['국가', '방문자수'],
      ],
      countryDataOptions: {
        title: '국가 & 언어별 방문자 통계',
        width: '100%',
        height: 600,
      },
      countryBarData : [
        ['국가', '방문자수'],
      ],
      countryBarOptions : {
        title: '국가 & 언어별 방문자 통계',
        width: '100%',
        height: 600,
        series: {
          0: { color: "#2ea178" },
        },
        vAxis: {minValue: 0},
        legend: { position: 'top' }
      },
      productData : [
        ['Product', '클릭 수'],
      ],
      productOptions : {
        title: '제품 클릭 횟수 상위 10개 막대 차트',
        width: '100%',
        height: 597,
        series: {
          0: { color: "#2ea178" },
        },
        vAxis: {minValue: 0},
        legend: { position: 'top' }
      },
      selectedDate : [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()],
      disabledEndDate: {
        to: new Date(),
        from: new Date()
      },
      dataForm: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric' 
      }
    }
  },
  methods: {
    exportPDF() {
      document.getElementById('headerBox').style.justifyContent = 'end';
      document.getElementById('pdfDownLoadBtn').style.display = 'none';
			html2canvas(document.getElementById("pdfArea"), {
        

        }).then(function(canvas) {
          var imgData = canvas.toDataURL('image/png');
          
          var imgWidth = 190; // 이미지 가로 길이(mm) / A4 기준 210mm
          var pageHeight = imgWidth * 1.414;  // 출력 페이지 세로 길이 계산 A4 기준
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var margin = 5; // 출력 페이지 여백설정
          var doc = new jsPDF('p', 'mm');
          var position = 30;
            
          // 첫 페이지 출력
          doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
              
          // 한 페이지 이상일 경우 루프 돌면서 출력
          while (heightLeft >= 20) {
              position = heightLeft - imgHeight;
              doc.addPage();
              doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
          }
      
          // 파일 저장
          doc.save('Statistical-Analysis.pdf');
          document.getElementById('pdfDownLoadBtn').style.display = 'block';
          document.getElementById('headerBox').style.justifyContent = 'space-between';
      });
    },
    leftPad(value) {
      if (value >= 10) {
          return value;
      }
      return `0${value}`;
    },
    toStringByFormatting(source, delimiter = '-') {
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());
      return [year, month, day].join(delimiter);
    },
    async inquiryData() {
      // const exhibitionId = this.$store.getters.getExhibitionId;
      const startDate = this.toStringByFormatting(new Date(this.selectedDate[0].getFullYear(), this.selectedDate[0].getMonth(), this.selectedDate[0].getDate()));
      const endDate = this.toStringByFormatting(new Date(this.selectedDate[1].getFullYear(), this.selectedDate[1].getMonth(), this.selectedDate[1].getDate()));
      this.initChartData();
      await this.getAllData(this.code, startDate, endDate);
    },
    async getAllData(exhibitionId, startDate, endDate) {
      try {
      const res = await axios.get(`https://mypage.360xcon.com/showroom/analytics/statistics/${exhibitionId}?startDate=${startDate}&endDate=${endDate}`);
        if (res.status == 200) {
          // 총 방문자
          this.totalVisits = res.data.data.visits

          // 제품 클릭 
          
          if (res.data.data.productClick) {
            for(var key in res.data.data.productClick) {
              if (Number(key)+1 < 11) {
                const list = [];
                list.push(`product_${Number(key)+1}`);
                list.push(res.data.data.productClick[key]);
                this.productData.push(list);
              }
            }
          }

          // 방문자 추이
          // console.log(res.data.data);
          for (let i=0; i<res.data.data.dailyVisits.length; i++) {
            const dataList = [];
            dataList.push(res.data.data.dailyVisits[i].date_str);
            dataList.push(res.data.data.dailyVisits[i].visits);
            this.chartData.push(dataList);
          }
          // 나라별 통계
          for (let i=0; i<res.data.data.country.length; i++) {
            const dataList = [];
            dataList.push(res.data.data.country[i]._id);
            dataList.push(res.data.data.country[i].count);
            this.countryBarData.push(dataList);
            this.countryData.push(dataList);
          }
        }
      } catch {
        this.initChartData();
      }
    },
    initChartData () {
      this.chartData = [['Days', '방문자수']],
      this.productData = [
        ['Product', '클릭 수'],
      ];
      this.countryBarData = [
        ['국가', '방문자수'],
      ];
      this.countryData= [
        ['국가', '방문자수'],
      ];
    },
    onSelect: function(items) {
      this.selected = items;
    },
    ...mapActions([
      "setExhibitionId",
      "setToken"
    ]),
  },
  async mounted () {
    try {
      const res = await axios.get(api.GET_DATA(this.$route.params.exhibitionId));
      if (res.status == 200) {
        this.code = res.data.data.code;
        const createDate = new Date(res.data.data.createdAt.split("T")[0]);
        if (createDate > this.selectedDate[0]) {
          this.selectedDate[0] = createDate;
        }
        
        this.disabledEndDate.from = new Date();
        this.disabledEndDate.to =  createDate;
      }
      this.initChartData();
      const startDate = this.toStringByFormatting(new Date(this.selectedDate[0].getFullYear(), this.selectedDate[0].getMonth(), this.selectedDate[0].getDate()));
      const endDate = this.toStringByFormatting(new Date(this.selectedDate[1].getFullYear(), this.selectedDate[1].getMonth(), this.selectedDate[1].getDate()));
      await this.getAllData(this.code, startDate, endDate);

    } catch (err) {
      console.log(err);
    }
  },
  beforeMount() {
    this.setExhibitionId({exhibitionId: this.$route.params.exhibitionId});
    if(this.$route.query && this.$route.query.token) {
      this.setToken({token: this.$route.query.token});
    }
  }
};
</script>

<style>
.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
    stroke: #2ea178;
    stroke-width: 5px;
}

.header-dashboard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 2rem 5rem;
}

.datePickerBox {
  display: flex;
}

.dashboard-btn {
  width: 20%;
  height: auto;
  margin-left: 2rem;
  background-color: #2ea178;
  border: none;
  border-radius: 7px;
  color: white;
  font-weight: 900;
  padding: 1rem;
  cursor: pointer;
}

.pdfDownLoadBtn {
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
}

.total-visits {
  width: 100vw;
  height: auto;
  margin: auto;
  padding: 1rem 5rem;
}

.total-visits-header {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: -1rem;
}

.country-visits {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 5rem;
      
}

.country-visits-line {
  width: 60%;
}

.country-visits-bar {
  width: 35%;
  height: 100%;
  margin: 0px;
}

.total-visits-block {
  width: 100%;
  text-align: right;
  padding: 0rem 5rem;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.vue-world-map {
  width: 90%;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.countryTitle {
  z-index: 9999;
  position: absolute;
  left: 8%;
  padding-top: 1rem;
  font-weight: 800;
}

.productTitle {
  padding-top: 1rem;
  font-weight: 800;
  padding-left: 1.2rem;
}

</style>
