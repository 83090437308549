<template>
  <div class="md-layout text-center login-fix-page">
    <!-- <div class="md-layout-item md-size-100">
      <div class="text-center">
        <h3>Log in to Vue Material Dashboard Laravel Live Preview</h3>
        <p>
          Log in to see how you can go from frontend to fullstack in an instant
          with an API-based Laravel backend.
        </p>
      </div>
      <div class="text-center" style="margin-bottom: 32px;">
        <h5><strong>You can log in with:</strong></h5>
        <div>Username <b>admin@jsonapi.com</b> Password <b>secret</b></div>
      </div>
    </div> -->

    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="loginForm">
        <login-card class="login-header" header-color="black">
          <h3 slot="title" class="title">Login</h3>
          <!-- <md-button
            slot="buttons"
            ref="#facebook"
            class="md-just-icon md-simple md-white"
          >
            <i class="fab fa-facebook-square"></i>
          </md-button>
          <md-button
            slot="buttons"
            href="#twitter"
            class="md-just-icon md-simple md-white"
          >
            <i class="fab fa-twitter"></i>
          </md-button>
          <md-button
            slot="buttons"
            href="#google"
            class="md-just-icon md-simple md-white"
          >
            <i class="fab fa-google-plus-g"></i>
          </md-button> -->
          <!-- <p slot="description" class="description">Or Be Classical</p> -->
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Email</label>
            <md-input v-model="email" type="email" />
            <validation-error :errors="apiValidationErrors.email" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Password</label>
            <md-input v-model="password" type="password" />
          </md-field>
          <md-button
            slot="footer"
            type="submit"
            class="md-simple md-success md-lg login-btn"
          >
            <div class="login-btn-text">Login</div>
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
  import {LoginCard, ValidationError} from "@/components";
  import formMixin from "@/mixins/form-mixin";
  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: {
      LoginCard, ValidationError
    },

    mixins: [formMixin],

    data: () => ({
      email: "",
      password: "",
    }),

    methods: {
      ...mapActions([
            "loginAdmin"
      ]),
      async loginForm() {
      try {
          const result = await this.loginAdmin({ 
              email: this.email,
              password: this.password
          });
          if(result) {
              this.$router.push("/showroom/66bafdc19a0749001477866a")
          }
          else {
              this.$store.dispatch("alerts/error", "아이디/비밀번호를 확인해 주세요.");
          }
      } catch (e) {
          await this.$store.dispatch("alerts/error", "Invalid credentials!");
      }
    }
  }
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>

<style>
.md-card-header-black {
  background-color: #ed4343 !important;
}
.login-btn-text {
  color: black;
}
</style>

