const api = {
    GET_DATA: (exhibitionId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/id`;
    },
    GET_DATA_DETAIL: (sceneId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/content/${number}`;
    },
    UPDATE_DATA_THUMBNAIL: (sceneId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/content/${number}/thumbnail`;
    },
    UPDATE_COMPANY_INFO: (exhibitionId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}`;
    },
    UPDATE_COMPANY_INFO_SUBLANG: (exhibitionId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/sub-lang`;
    },
    UPDATE_INVI_CODE: (exhibitionId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/invitation`;
    },
    UPDATE_BGM: (exhibitionId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/bgm`;
    },
    GET_THEME_LIST: (type) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/theme/type/${type}`;
    },
    UPDATE_THEME: (exhibitionId, themeId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/theme/${themeId}`;
    },
    GET_ALL_CONTENTS: (exhibitionId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/content`;
    },
    UPDATE_CONTENT_STATUS: (exhibitionId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/content/${number}/status`;
    },
    GET_CONTENT_DETAIL: (exhibitionId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/content/${number}`;
    },
    UPDATE_CONTENT_THUMBNAIL: (exhibitionId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/content/${number}/thumbnail`;
    },
    UPDATE_CONTENT: (exhibitionId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/exhibition/${exhibitionId}/content/${number}`;
    },

    UPDATE_DOCENT: (sceneId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/docent`;
    },
    GET_ALL_LINK: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link/list`;
    },
    POST_LINK: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link`;
    },
    UPDATE_LINK: (linkId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link/${linkId}`;
    },
    DELETE_LINK: (linkId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link/${linkId}`;
    },
    GET_QUESTLOG: (page, limit=10) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/quest-log/list?page=${page}&limit=${limit}`;
    },
    DELETE_QUESTLOG: (questLogId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/quest-log/${questLogId}`;
    },
    LOGIN: () => {
        return `${process.env.VUE_APP_AUTH_BASE_URL}/api/v1/auth/user/login`
    },
    LOGOUT: () => {
        return `${process.env.VUE_APP_AUTH_BASE_URL}/api/v1/auth/user/logout`
    },
    GET_USER_INFO: () => {
        return `${process.env.VUE_APP_AUTH_BASE_URL}/api/v1/auth/user`
    },
    UPDATE_USER_PW: () => {
        return `${process.env.VUE_APP_AUTH_BASE_URL}/api/v1/auth/user`
    },
    GET_CONFERENCE_ALERT: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/alert`
    },
    UPDATE_CONFERENCE_ALERT: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/alert`
    },
}

export default api;