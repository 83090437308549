import jwt from 'jsonwebtoken';
import axios from 'axios';
import api from '@/commons/apiUrl';

const AuthStore = {
    state: {
        token: "",
        loginStatus: false,
        exhibitionId: ""
    },
    mutations: {
        setToken(state, authToken) {
            state.token = authToken;
        },
        setStatus(state, status) {
            state.loginStatus = status;
        },
        setExhibitionId(state, exhibitionId) {
            state.exhibitionId = exhibitionId;
        },
    },
    getters: {
        getToken(state) {
            if(state.token !== "") {
                try {
                    const decoded = jwt.verify(state.token, process.env.VUE_APP_JWT_SECRET);
                    if(!decoded) return null;
                    
                    return `Bearer ${state.token}`;
                }
                catch(error) {
                    return null;
                }
            }

            return null;
        },
        getLoginStatus(state) {
            return state.loginStatus;
        },
        getExhibitionId(state) {
            return state.exhibitionId;
        }
    },
    actions: {
        async loginAdmin({commit}, params) {
            const res = await axios.post(api.LOGIN(), {
                email: params.email,
                password: params.password
            });
            
            const success = res.data.success;
            if(success) {
                commit("setToken", res.data.data);
                commit("setStatus", true);
            }
            else {
                commit("setToken", "");
                commit("setStatus", false);
            }

            return success;
        },
        async logoutAdmin({commit}) {
            const res = await axios.post(api.LOGOUT());

            const success = res.data.success;
            if(success) {
                commit("setToken", "");
                commit("setStatus", false);
            }

            return success;
        },
        setExhibitionId({commit}, params) {
            commit("setExhibitionId", params.exhibitionId);
        },
        setToken({commit}, params) {
            commit("setToken", params.token);
            commit("setStatus", true);
        }
    }
}

export default AuthStore;