<template>
  <GChart :type="type" :data="data" :options="options" :settings="settings" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';


export default {
  name: 'GoogleChart',
  components: {
    GChart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'LineChart',
    },
    settings: {
      type: Object,
      default: () => {},
    },
  }
};
</script>
