<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
.md-card .md-card-header-green .card-icon, .md-card .md-card-header-green .card-text, .md-card .md-card-header-green:not(.md-card-header-icon):not(.md-card-header-text), .md-card [data-background-color=green], .md-card .md-tabs.md-success .md-tabs-navigation {
    background: linear-gradient(60deg, #489a78, #38ac90) !important;
}
</style>
